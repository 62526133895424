import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createClips = createAsyncThunk(
    "createClips",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/create/`, data,
                {
                    headers: config.headers,
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(setUploadProgress(percentCompleted));
                    },
                }
            );
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getClips = createAsyncThunk(
    "getClips",
    async ({ page }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/list/?page=${page}`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateClips = createAsyncThunk(
    "updateClips",
    async ({ clipId, formData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.patch(`${mainUrl}api/clips/update/${clipId}/`, formData, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteClips = createAsyncThunk(
    "deleteClips",
    async ({ clipId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/clips/delete/${clipId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const viewClips = createAsyncThunk(
    "viewClips",
    async (post_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/view/${post_id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const randomClips = createAsyncThunk(
    "randomClips",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/ids/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const getClipsDetails = createAsyncThunk(
    "getClipsDetails",
    async (post_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/postdetail/${post_id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const clipsSlice = createSlice({
    name: "clips",
    initialState: {
        loading: false,
        videoData: null,
        videoDescription: null,
        clipsData: null,
        randomClipsId: [],
        clipsDetails: [],
        playedClips: [],
        error: null,
        progress: 0,
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
        setUploadingData: (state, action) => {
            state.videoData = action.payload.videoData;
            state.videoDescription = action.payload.videoDescription;
        }
        ,
        setPlayedClips: (state, action) => {
            state.playedClips = [...state.playedClips, action.payload];
            const uniqueArray = [...new Set(state.playedClips)];
            state.playedClips = uniqueArray;
        }
    },
    extraReducers: (builder) => {
        // Create clips
        builder.addCase(createClips.pending, (state) => {
            state.loading = true;
            state.clipsData = null;
            state.error = null;
        });
        builder.addCase(createClips.fulfilled, (state, action) => {
            state.loading = false;
            state.clipsData = action.payload;
            state.error = null;
        });
        builder.addCase(createClips.rejected, (state) => {
            state.loading = false;
            state.clipsData = null;
            state.error = true;
            state.progress = 0
        });

        // Get clips
        builder.addCase(getClips.pending, (state) => {
            state.loading = true;
            state.clipsData = null;
            state.error = null;
        });
        builder.addCase(getClips.fulfilled, (state, action) => {
            state.loading = false;
            state.clipsData = action.payload;
            state.error = null;
        });
        builder.addCase(getClips.rejected, (state) => {
            state.loading = false;
            state.clipsData = null;
            state.error = true;
        });
        // Get view clips
        builder.addCase(viewClips.pending, (state) => {
            // state.loading = true;
            state.error = null;
        });
        builder.addCase(viewClips.fulfilled, (state) => {
            // state.loading = false;
            state.error = null;
        });
        builder.addCase(viewClips.rejected, (state) => {
            // state.loading = false;
            state.error = true;
        });
        // Get random clips
        builder.addCase(randomClips.pending, (state) => {
            state.loading = true;
            state.randomClipsId = null;
            state.error = null;
        });
        builder.addCase(randomClips.fulfilled, (state, action) => {
            state.loading = false;
            state.randomClipsId = action.payload;
            state.error = null;
        });
        builder.addCase(randomClips.rejected, (state) => {
            state.loading = false;
            state.randomClipsId = null;
            state.error = true;
        });
        // Get clips details
        builder.addCase(getClipsDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getClipsDetails.fulfilled, (state, action) => {
            state.loading = false;
            console.log(action.payload, "action.payload")
            // Remove duplicates based on action.payload.id
            state.clipsDetails = [
                ...state.clipsDetails.filter(clip => clip.id !== action.payload.id),
                action.payload
            ];
            state.error = null;
        });
        builder.addCase(getClipsDetails.rejected, (state) => {
            state.loading = false;
            state.clipsDetails = [];
            state.error = true;
        });
        //update clips
        builder.addCase(updateClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateClips.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateClips.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //delete clips
        builder.addCase(deleteClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteClips.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteClips.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const { setUploadProgress, setUploadingData, setPlayedClips } = clipsSlice.actions;
export default clipsSlice.reducer;