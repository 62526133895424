import React, { Fragment, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

const Drawer = ({ isOpen, onClose, children, channelName, height }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
        } else {
            setTimeout(() => setIsVisible(false), 500); // Delay to match animation
        }
    }, [isOpen]);

    return (
        <Fragment>
            {isVisible && (
                <div
                    onClick={() => {onClose();}}
                    className={`absolute inset-0 bg-black z-10 transition-opacity duration-500 ${isOpen ? "opacity-70" : "opacity-0"}`}
                />
            )}
            <div className={`absolute bottom-0 left-0 w-full bg-[#191919] z-10 transition-transform duration-500 
                ${isOpen ? "translate-y-0" : "translate-y-full"}`}
                style={{ height: height }}
            >
                <div className="grid grid-cols-12 gap-x-2 px-2 sticky top-0 bg-[#191919] pt-2 pb-1 border-b-[0.4px] border-[#474747]">
                    <div className="col-span-11 flex items-center justify-start">
                        <p className="text-white text-[16px] md:text-[14px] line-clamp-1">
                            {channelName}
                        </p>
                    </div>
                    <div className="col-span-1 flex items-center justify-end">
                        <IoClose
                            onClick={() => {
                                onClose();
                            }}
                            className="text-white text-[20px] cursor-pointer"
                        />
                    </div>
                </div>
                {children}
            </div>
        </Fragment>
    );
};

export default Drawer;