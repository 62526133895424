import React, { useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { clearHistory, deleteHistoryById, getHistoryList } from "../redux/apislice/watchHistorySlice";
import Skeleton from "react-loading-skeleton";

const HistoryPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = useSelector((state) => state.watchHistoryList);
    const [checked, setChecked] = useState([]);
    const { historyList, isLoading } = history

    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }
    const handleCheck = (e, id) => {
        if (e.target.checked) {
            setChecked([...checked, id])
        } else {
            setChecked(checked.filter((item) => item !== id))
        }
    }
    const handleDeleteHistory = (value) => {
        if (value === "Checked") {
            dispatch(deleteHistoryById({ ids: checked })).then((res) => {
                if (res.error) {
                    console.log(res)
                }
                else {
                    setChecked([])
                    dispatch(getHistoryList())
                }
            })
        }
        else {
            dispatch(clearHistory()).then((res) => {
                if (res.error) {
                    console.log(res)
                }
                else {
                    setChecked([])
                    dispatch(getHistoryList())
                }
            })
        }
    }
    useEffect(() => {
        dispatch(getHistoryList())
    }, [dispatch]);

    return (
        <div>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / History / V1 / oct 14 , 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>
            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">History</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>
            {historyList?.results?.length !== 0 && 
            <div className="text-blue-700 w-full flex justify-end items-center text-[11px] cursor-pointer px-2 py-1">
                <p onClick={() => handleDeleteHistory(checked.length > 0 ? "Checked" : null)}>
                    {checked.length > 0 ? "Delete History" : "Clear History"}
                </p>
            </div>}
            {/* History data will be displayed here */}
            <div className="grid grid-cols-12 gap-3 mt-2 px-2 pb-2">
                {!isLoading && historyList?.results?.map((data) => (
                    <div className="col-span-6 relative" key={data.id}>
                        <img
                            alt="history video"
                            src={data.video.thumbnail}
                            onClick={() => navigate(`/video/${data.video.id}`)}
                            className="w-full h-[5.5rem] object-cover rounded cursor-pointer border-b-[3px] border-[#f43b3b] hover:opacity-80"
                        />
                        <input type="checkbox" checked={checked.includes(data.id)} onChange={(e) => handleCheck(e, data.id)} className="absolute top-2 right-2 bg-gray-200" />

                        <p className="text-[10px] line-clamp-2 text-white leading-[14px] mt-1">
                            {data.video.title}
                        </p>
                        <div className="flex items-center gap-2">
                            <p className="text-[#808080] text-[10px]">{data.video.views_count} views</p>
                            <p className="text-[#808080] text-[10px]">•</p>
                            <p className="text-[#808080] text-[10px]">{timeAgo(data.watched_at)}</p>
                        </div>
                    </div>
                ))}
                {isLoading && (
                    [1, 2, 3, 4, 5, 6].map(() => (<div className="col-span-6">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ width: "100%", height: "5.5rem" }} />
                        <p className="text-[10px] line-clamp-2 text-white leading-[14px] mt-1">
                            <Skeleton baseColor="#202020" highlightColor="#444" />
                        </p>
                        <div className="flex items-center gap-2">
                            <p className="text-[#808080] text-[10px]">   <Skeleton baseColor="#202020" highlightColor="#444" width={60} /></p>
                            <p className="text-[#808080] text-[10px]">•</p>
                            <p className="text-[#808080] text-[10px]"> <Skeleton baseColor="#202020" highlightColor="#444" width={72} /></p>
                        </div>
                    </div>))
                )}
                {historyList?.results?.length === 0 && (
                    <div className="col-span-12 text-center text-white mt-4">
                        <p className="text-[#4d4d4d] px-1 text-[14px]">No history found</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HistoryPage