import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const getUserPodcast = createAsyncThunk(
    "getUserPodcast",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/podcast/podcasts/login_user/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updatePodcast = createAsyncThunk(
    "updatePodcast",
    async ({ updatedData, podcastId, progressCallback }, { rejectWithValue }) => {
        try {
            const config = {
                ...getAccessConfig(),
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (progressCallback) progressCallback(progress);
                },
            };
            const request = await axios.patch(`${mainUrl}api/podcast/podcasts/${podcastId}/`, updatedData, config);

            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deletePodcast = createAsyncThunk("deletePodcast", async ({ podcastId }, { rejectWithValue }) => {
    try {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/podcast/podcasts/${podcastId}/`, config);
        const response = await request.data;
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

const userPodcastSlice = createSlice({
    name: "userPodcast",
    initialState: {
        loading: false,
        userPodcast: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create post
        builder.addCase(getUserPodcast.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getUserPodcast.fulfilled, (state, action) => {
            state.loading = false;
            state.userPodcast = action.payload;
            state.error = null;;
        });
        builder.addCase(getUserPodcast.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

    }
});

export default userPodcastSlice.reducer;