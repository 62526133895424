import { useMemo } from 'react';

function useTimeAgo(timestamps) {
    return useMemo(() => {
        const now = new Date();
        return timestamps.map((timestamp) => {
            const past = new Date(timestamp);
            const diffInSeconds = Math.floor((now - past) / 1000);

            const intervals = {
                year: 31536000,
                month: 2592000,
                week: 604800,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1,
            };

            for (const [unit, seconds] of Object.entries(intervals)) {
                const interval = Math.floor(diffInSeconds / seconds);
                if (interval >= 1) {
                    return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
                }
            }
            return 'just now';
        });
    }, [timestamps]);
}

export default useTimeAgo;
