import React, { Fragment, useState } from "react";
import { mainHomePageData } from "../../data";
import { FaRegEyeSlash } from "react-icons/fa6";
import { GoBellSlash } from "react-icons/go";
import Drawer from "../../components/common/Drawer";

const Default = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [channelName, setChannelName] = useState("");

    const action = [
        { id: 1, icon: <FaRegEyeSlash />, text: "Hide this notification" },
        { id: 2, icon: <GoBellSlash />, text: `Turn off all from ${channelName}` }
    ];

    return (
        <Fragment>
            {mainHomePageData.map((data) => (
                <div className="grid grid-cols-12 gap-2 mb-2" key={data.id}>
                    <div className="col-span-4">
                        <img
                            alt="img"
                            src={data.thumbnail}
                            className="w-full h-[4.5rem] md:h-[3.7rem] object-cover rounded-md cursor-pointer"
                        />
                    </div>
                    <div className="col-span-6">
                        <p className="text-white text-[12px] md:text-[10px] font-semibold line-clamp-1">
                            {data.channel}
                        </p>
                        <p className="text-[#565656] text-[12px] md:text-[10px] mt-1 leading-[14px] md:leading-[12px] line-clamp-2">
                            {data.title}
                        </p>
                        <p className="text-cyan-600 text-[12px] md:text-[10px] mt-1">{data.days}</p>
                    </div>
                    <div className="col-span-2 flex items-start justify-between">
                        <img
                            alt="img"
                            src={data.channelLogo}
                            className="h-10 w-10 md:h-8 md:w-8 rounded-full object-cover"
                        />
                        <p
                            onClick={() => {
                                setIsOpen(true);
                                setChannelName(data.channel);
                            }}
                            className="text-white text-[12px] md:text-[10px] cursor-pointer mt-[-4px]"
                        >
                            : :
                        </p>
                    </div>
                </div>
            ))}

            <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} channelName={channelName} height={"26vh"}>
                {action.map((data) => (
                    <div className="flex items-center gap-3 pt-2 px-2" key={data.id}>
                        {data.icon && (
                            <div className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[20px] md:text-[16px]">
                                {data.icon}
                            </div>
                        )}
                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px] md:text-[14px]">
                            {data.text}
                        </p>
                    </div>
                ))}
            </Drawer>
        </Fragment>
    )
}

export default Default;