import axios from "axios";
import getAccessConfig from "../../config";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const getNotifications = createAsyncThunk(
    "getNotifications",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/notifications/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const readNotification = createAsyncThunk(
    "readNotification",
    async ({ id, read }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.patch(`${mainUrl}api/notifications/${id}/`, {read}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteNotification = createAsyncThunk(
    "deleteNotification",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            await axios.delete(`${mainUrl}api/notifications/${id}/`, config);
            return id; // Return the deleted notification ID
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        loading: false,
        data: [],
        readData: [],
        error: null,
    },
    extraReducers: (builder) => {
        // getNotifications
        builder.addCase(getNotifications.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(getNotifications.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // readNotification
        builder.addCase(readNotification.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(readNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.readData = action.payload;
            state.error = null;
        });
        builder.addCase(readNotification.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // deleteNotification
        builder.addCase(deleteNotification.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.data = state.data.filter(event => event.id !== action.payload);
        });
        builder.addCase(deleteNotification.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default notificationSlice.reducer;