import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";
import { HiDotsVertical } from "react-icons/hi";
import { RiEdit2Fill } from "react-icons/ri";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Button } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';


export default function Clips({ handleEditClip, handleDelete, getClips }) {
    const navigate = useNavigate();
    const clipsList = useSelector((state) => state.postedClips);
    const { postedClips, loading } = clipsList;
    const [clips, setClips] = useState([]);
    const [videoDurations, setVideoDurations] = useState({});
    const [videoRefs, setVideoRefs] = useState([]);
    const [isPlaying, setIsPlaying] = useState({});
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [menuId, setMenuId] = useState(null);
    const menuRef = useRef();



    const formatDuration = (duration) => {
        const seconds = Math.floor(duration % 60);
        const minutes = Math.floor((duration % 3600) / 60);
        const hours = Math.floor(duration / 3600);

        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedHours = hours > 0 ? `${hours}:` : '';

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };

    const handleTimeUpdate = (index) => {
        console.log(index, "index")
        const videoElement = videoRefs[index].current;
        setVideoDurations((prevDurations) => ({
            ...prevDurations,
            [index]: formatDuration(videoElement.currentTime),
        }));
    };


    const handlePlayPause = (index) => {
        const videoElement = videoRefs[index].current;

        if (videoElement?.paused) {
            // Pause the currently playing video if there is one
            if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
                const currentPlayingVideo = videoRefs[currentPlayingIndex].current;
                currentPlayingVideo.pause();
                setIsPlaying((prev) => ({ ...prev, [currentPlayingIndex]: false }));
            }

            videoElement?.play();
            setIsPlaying((prev) => ({ ...prev, [index]: true }));
            setCurrentPlayingIndex(index);
        } else {
            videoElement?.pause();
            setIsPlaying((prev) => ({ ...prev, [index]: false }));
            setCurrentPlayingIndex(null);
        }
    };

    useEffect(() => {
        clips?.forEach((data) => {
            const videoElement = document.createElement('video');
            videoElement.src = data.video;

            const handleLoadedMetadata = () => {
                setVideoDurations((prevDurations) => ({
                    ...prevDurations,
                    [data.id]: formatDuration(videoElement.duration),
                }));
            };

            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

            // Clean up the event listener
            return () => {
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            };
        });
    }, [clips]);

    useEffect(() => {
        setClips(postedClips?.results)
    }, [postedClips?.results])

    useEffect(() => {
        getClips();
    }, [getClips]);


    useEffect(() => {
        // Create refs for each video element
        setVideoRefs((refs) =>
            Array(clips?.length)
                .fill()
                .map((_, i) => refs[i] || React.createRef())
        );
    }, [clips]);

    return (
        <div className='col-span-12 grid grid-cols-12 gap-2 px-3'>
            {
                loading ?
                    [1, 2, 3, 4, 5, 6, 7].map((_, index) => (<div key={index} className="col-span-4 grid grid-cols-12">
                        <div className="col-span-12">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                        </div>
                    </div>
                    ))
                    : clips?.map((clip, index) => (
                        <div className='col-span-4 relative group' key={index}>
                            <video
                                ref={videoRefs[index]}
                                onTimeUpdate={() => handleTimeUpdate(index)}
                                src={clip.video} disablePictureInPicture controls={false} className='w-full object-cover rounded-md h-40' />
                            <div className='absolute bottom-1 flex justify-between w-full px-2 items-center'>
                                <p className='text-white font-semibold text-[10px]'>{videoDurations[clip.id]}</p>
                                {isPlaying[index] ?
                                    <FaCirclePause
                                        className='text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer'
                                        onClick={() => handlePlayPause(index)}
                                    />
                                    : <FaCirclePlay onClick={() => handlePlayPause(index)} className='text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer' />}
                            </div>
                            <div className='absolute top-1 right-1'>
                                <div className="relative">
                                    <HiDotsVertical className='text-white cursor-pointer' onClick={() => menuId ? setMenuId(null) : setMenuId(clip?.id)} />
                                    {menuId === clip?.id && (
                                        <div ref={menuRef} className='absolute top-4 right-0 bg-black p-1 rounded-[4px] shadow-md'>
                                            <div onClick={() => handleEditClip(clip)} className='flex items-center gap-1 text-white hover:text-red-600 text-[10px] font-semibold cursor-pointer'>
                                                <RiEdit2Fill />
                                                <p>Edit</p>
                                            </div>
                                            <div className='flex items-center gap-1 text-white hover:text-red-600 text-[10px] font-semibold cursor-pointer'>
                                                <RiDeleteBin5Fill />
                                                <p onClick={() => handleDelete(clip?.id)}>Delete</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}

            {clips?.length === 0 && <div className='col-span-12 flex flex-col gap-y-4 justify-center items-center'>
                <p className='text-gray-700 text-[16px] mt-10 font-bold'>No clips found</p>
                <Button>
                    <p className='text-white text-[12px]' onClick={() => navigate("/createclips")}>Create Clip</p>
                </Button>
            </div>}
        </div>
    )
}
