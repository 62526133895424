import React, { useState, useCallback, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deletePodcast, getUserPodcast } from '../../redux/apislice/podcast/userPodcastSlice';
import useTimeAgo from '../../hooks/useTimeAgo';
import { FaRegEdit, FaShare } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';

export default function Podcast() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const podcast1 = useSelector((state) => state.userPostedPodcast);
    const { loading, userPodcast: podcastData } = podcast1;
    const [activePopupId, setActivePopupId] = useState(null);

    const fetchPodcast = useCallback(async () => {
        await dispatch(getUserPodcast());
    }, [dispatch]);

    const handleDeletePodcast = async (podcastId) => {
        // Delete podcast
        const responce = await dispatch(deletePodcast({ podcastId }))
        if (responce.type === "deletePodcast/fulfilled") {
            fetchPodcast();
        } else {
            console.log(responce, "error")
        }
    }


    useEffect(() => {
        fetchPodcast();
    }, [fetchPodcast]);

    // Extract timestamps if podcast data is available
    const timestamps = podcastData.results?.map((data) => data.created_at) || [];
    const formattedTimes = useTimeAgo(timestamps);

    return (
        <div className="col-span-12">
            <div className="bg-black h-full overflow-y-scroll">
                {loading &&
                    [1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                        <div key={index} className="grid grid-cols-12 px-2 py-1">
                            <div className="col-span-12">
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                            </div>
                            <div className="col-span-2">
                                <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "2rem", width: "2rem" }} />
                            </div>
                            <div className="col-span-9 flex flex-col justify-center -ml-2">
                                <div>
                                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "60%" }} />
                                </div>
                                <div className="-mt-10">
                                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "100%" }} />
                                </div>
                            </div>
                            <div className="col-span-1 flex justify-end">
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "12px", width: "12px", borderRadius: "1px" }} />
                            </div>
                        </div>
                    ))}

                {podcastData.results?.length > 0 ? (
                    podcastData.results.map((data, index) => {
                        return (
                            <div className="pb-2" key={data.id}>
                                <img
                                    alt="thumbnail"
                                    src={data.thumbnail}
                                    onClick={() => navigate(`/podcast/video/${data?.id}`)}
                                    className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                                />

                                <div className="grid grid-cols-12 mt-1 px-2">
                                    <div className="col-span-2">
                                        <img
                                            alt="profile"
                                            className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                            src={data?.creator_profile_picture}
                                        />
                                        <div className="flex flex-col items-center justify-start ml-[-12px]">
                                            <p className="text-white text-[13px]">{data.view_count}</p>
                                            <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                        </div>
                                    </div>
                                    <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                                        <p className="text-white text-[14px] font-light line-clamp-1">{data.host_name}</p>
                                        <p className="text-[#8d8d8d] text-[12px]">
                                            {data.creator_channel_name} - {formattedTimes[index]}
                                        </p>
                                    </div>
                                    <div className="col-span-1 relative flex items-start justify-end mt-[-2px]">
                                        <p
                                            onClick={() => activePopupId ? setActivePopupId(null) : setActivePopupId(data.id)}
                                            className="text-white text-[14px] cursor-pointer"
                                        >
                                            : :
                                        </p>
                                        {activePopupId === data.id && (
                                            <div className="absolute -top-14 right-0 bg-[#f8f8f8] rounded">
                                                <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                    <FaShare className="text-[11px]" />
                                                    <p className="text-[10px]">Share</p>
                                                </div>
                                                <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                    <FaRegEdit className="text-[11px]" />
                                                    <p className="text-[10px]" onClick={() => navigate(`/edit/podcast/${data?.id}`)} >Edit</p>
                                                </div>
                                                <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                                    <RiDeleteBin5Fill className="text-[11px]" />
                                                    <p className="text-[10px]" onClick={() => handleDeletePodcast(data?.id)} >Delete</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    !loading && <p className="text-[#4d4d4d] px-1 text-[14px] text-center mt-4">No results found</p>
                )}
            </div>
        </div>
    );
}

