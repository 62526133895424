import React, { Fragment, useEffect, useState } from "react";
import { CiFlag1 } from "react-icons/ci";
import { FiDownload } from "react-icons/fi";
import { GoBookmark } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import { FaRegComment } from "react-icons/fa6";
import { MdOutlineWatchLater } from "react-icons/md";
import { LiaUserAltSlashSolid } from "react-icons/lia";
import { IoBanOutline, IoClose } from "react-icons/io5";
import { Toaster } from "react-hot-toast";

const actions = [
    { id: 1, icon: <FiDownload />, text: "Download the video" },
    { id: 2, icon: <MdOutlineWatchLater />, text: "I’ll watch this later" },
    { id: 3, icon: <GoBookmark />, text: "Add to my playlist" },
    { id: 4, icon: <PiShareFat />, text: "Share the current video" },
    { id: 5, icon: <FaRegComment />, text: "Comment on this video" },
    { id: 6, icon: <LiaUserAltSlashSolid />, text: "Don’t show this channel" },
    { id: 7, icon: <IoBanOutline />, text: "I’m not interested" },
    { id: 8, icon: <CiFlag1 />, text: "Report" },
];

const FourDotDrawer = ({
    title,
    videoId,
    onClose,
    savePlaylist,
    handleShare,
    handleBlockChannel,
    channelId,
    handleDownload,
    handleNotIntrested,
    handleAddWatchLater
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClicked = (action) => {
        switch (action) {
            case 1:
                return handleDownload(videoId)
            case 3:
                return savePlaylist()
            case 2:
                return handleAddWatchLater(videoId)
            case 4:
                return handleShare(videoId)
            case 6:
                return handleBlockChannel(channelId)
            case 7:
                return handleNotIntrested(videoId)
            default:
                onClose();
                break;
        }
    }

    useEffect(() => {
        setIsOpen(true);
    }, []);

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div
                onClick={() => {
                    setIsOpen(false);
                    setTimeout(onClose, 500); // Delay onClose to match animation
                }}
                className={`absolute inset-0 bg-black z-10 transition-opacity duration-500 
                    ${isOpen ? "opacity-50" : "opacity-0"}`
                }
            />
            <div className={`absolute bottom-0 w-full bg-[#191919] h-[50vh] z-10 transition-transform duration-500 
                ${isOpen ? "translate-y-0" : "translate-y-full"}`}
            >
                <div className="grid grid-cols-12 gap-x-2 px-2 sticky top-0 bg-[#191919] pt-2 pb-1 border-b-[0.4px] border-[#474747]">
                    <div className="col-span-11 flex items-center justify-start">
                        <p className="text-white text-[16px] md:text-[14px] line-clamp-1">
                            {title}
                        </p>
                    </div>
                    <div className="col-span-1 flex items-center justify-end">
                        <IoClose
                            onClick={() => {
                                setIsOpen(false);
                                setTimeout(onClose, 500); // Delay onClose to match animation
                            }}
                            className="text-white text-[20px] cursor-pointer"
                        />
                    </div>
                </div>
                {actions.map((action, index) => (
                    <div className="flex items-center gap-3 pt-2 px-2" key={index} onClick={() => handleClicked(action.id)}>
                        {action.icon && (
                            <div className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[20px] md:text-[16px]">
                                {action.icon}
                            </div>
                        )}
                        <p className="text-[#d4d4d4] hover:text-[#ffffff] cursor-pointer text-[16px] md:text-[14px]">
                            {action.text}
                        </p>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default FourDotDrawer;
