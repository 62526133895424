import React, { useCallback, useEffect, useRef, useState } from "react";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { recoveryPass, verifyOtp } from "../redux/apislice/recoveryPasswordSlice";
import toast, { Toaster } from "react-hot-toast";

const EnterOTP = () => {
    const lable = "KV / FE / ENTER OTP / V1 / 27 July, 2024";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Decrypt data using AES decryption
    const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    const numberOfDigits = 6;
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);
    const [timeLeft, setTimeLeft] = useState(360); // 6 minutes in seconds
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimerActive(false);
            return;
        }

        if (timerActive) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
            2,
            "0"
        )}`;
    };

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData("text");
        if (/^[0-9]{6}$/.test(paste)) {
            const newOtp = paste.split("");
            setOtp(newOtp);
            otpBoxReference.current.forEach((input, index) => {
                input.value = newOtp[index];
            });
        }
        event.preventDefault();
    };
    const decryptedEmail = decryptData(Cookies.get("email_id"))


    const handleVerifyOtp = useCallback(() => {
        const otpData = otp.join("");
        const payload = {
            email: decryptedEmail,
            otp: otpData,
        };
        const toastId = toast.loading("Verifying OTP...");
        try {
            dispatch(verifyOtp(payload))?.then((result) => {
                if (result.error) {
                    toast.error("Failed to verify OTP.", { id: toastId });
                    setOtpError("Invalid OTP. Please try again.");
                } else {
                    toast.success(result.payload.detail, { id: toastId });
                    Cookies.set("otp", otpData);
                    if (result.payload.detail) {
                        setTimeout(() => {
                            navigate("/changepass");
                        }, 2000);
                    }
                }
            });
        } catch (error) {
            toast.error("Failed to verify OTP.");
        }
    }, [dispatch, navigate, otp, decryptedEmail]);


    function validateEmail(email) {
        // Regular expression for validating an email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const resendOtp = async (e) => {
        e.preventDefault();
        if (timeLeft) {
            return null
        }

        if (validateEmail(decryptedEmail) && decryptedEmail.length > 0) {
            const toastId = toast.loading("Sending OTP...");
            try {
                const result = await dispatch(recoveryPass({ email: decryptedEmail }));
                if (result.error) {
                    toast.error(result.error, { id: toastId });
                } else {
                    toast.success("OTP sent successfully!", { id: toastId });
                    setTimeLeft(360)
                }
            } catch (error) {
                toast.error("Failed to send OTP.", { id: toastId });
                console.error("error", error);
            }
        } else {
            toast.error("Please enter a valid email address.");
        }
    };

    useEffect(() => {
        if (otp.join("").length === numberOfDigits) {
            handleVerifyOtp()
        } else {
            setOtpError(null);
        }
    }, [otp, handleVerifyOtp]);


    return (
        <div className="h-full bg-[#2C672E] relative">
            <Toaster position="right-top" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>

            <h1 className="px-3 text-[3rem] font-semibold uppercase text-white">
                kv
            </h1>

            <form className="px-6 mt-14">
                <p className="text-white font-semibold text-[28px] md:text-[24px] text-center">
                    Enter OTP
                </p>
                <div className="mt-4">
                    <label className="block text-[#D9D9D9] font-normal text-[16px] md:text-[12px]" htmlFor="email">
                        {decryptedEmail}
                    </label>
                    <div className="flex items-center justify-between mt-3">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                required
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleChange(e.target.value, index)}
                                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                ref={(reference) =>
                                    (otpBoxReference.current[index] = reference)
                                }
                                className="h-12 md:h-10 w-12 md:w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                                onPaste={handlePaste}
                            />
                        ))}
                    </div>
                    {otpError && (<div className="flex justify-center my-3 items-center">
                        <p className="text-red-500 font-bold text-[12px]">{otpError}</p>
                    </div>)}
                </div>
                <div className="mt-4 md:mt-2 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <p className="text-[16px] md:text-[12px] text-white"> {formatTime(timeLeft)}</p>
                        <p onClick={resendOtp} className={`cursor-pointer text-[16px] md:text-[12px] ${!timeLeft ? "text-[#0DD315]" : "text-[#e96969]"}  font-semibold`}>
                            Resend OTP
                        </p>
                    </div>
                    <p
                        onClick={() => navigate("/recoverpass")}
                        className="cursor-pointer text-[16px] md:text-[12px] text-[#0DD315] font-semibold"
                    >
                        Lost my mail
                    </p>
                </div>
            </form>

            <div className="absolute bottom-[10rem] w-full px-14">
                <div className="flex items-center justify-evenly">
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-black h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                </div>
            </div>
        </div>
    )
}

export default EnterOTP