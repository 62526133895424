import React, { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate, useParams } from "react-router-dom";
import { fetchVideoDetails, likeVideo } from "../../redux/apislice/videoDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { createSubscription, DeleteSubscription, getUserSubscription, getAllUserSubscription } from "../../redux/apislice/subscriptionSlice";
import Cookies from 'js-cookie';
import toast, { Toaster } from "react-hot-toast";
import CommentView from "./CommentView";
import VideoControls from "./VideoControls";
import RelatedVideos from "./RelatedVideos";
import VideoHeader from "./VideoHeader";
import MainVideoControls from "./MainVideoControls";
import FourDotDrawer from "../common/FourDotDrawer";
import { createComment, createCommentReply, deleteComment, deleteReplies, getComments, getReplies, likeComment, removeLikeDislikeComment, updateComments, updateCommentReply } from "../../redux/apislice/commentSlice";
import { getDownload } from "../../redux/apislice/downloadSlice";
import Share from "../clips/Share";
import { blockChannel, blockVideo } from "../../redux/apislice/blockSlice";
import { addWatchList } from "../../redux/apislice/watchListSlice";
import LoadingSkeletonVideoDetails from "../common/LoadingSkeletonVideoDetails";
import Skeleton from "react-loading-skeleton";

const VideoDetails = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const videoRef = useRef(null);

    const [isSubscribe, setIsSubscribe] = useState(true);
    const [menu, setMenu] = useState(false);
    const [showComment, setShowComment] = useState(true);
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const video = useSelector((state) => state.videoDetails);
    const { videoData, isLoading } = video;
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile;

    console.log(videoData?.related_videos, "relatedtVideos")

    const subscriptionlist = useSelector((state) => state.subscription);
    const { subscribeList, userSubscribeList } = subscriptionlist;

    const [progress, setProgress] = useState(0);

    const [mute, setMute] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [openVideoInfo, setOpenVideoInfo] = useState(null);
    const user = Cookies.get("userId");
    const commentsData = useSelector((state) => state.comment)
    const { comments, replies, commentLoading, repliesLoading } = commentsData;
    const [videoComments, setVideoComments] = useState([])
    const [commentReplies, setCommentReplies] = useState([])


    // comment section
    const [comment, setComment] = useState("");
    const [editComment, setEditComment] = useState(false)
    const [editReply, setEditReply] = useState(false)
    const [replyId, setReplyId] = useState(null)
    const [reply, setReply] = useState('')
    const [commentId, setCommentId] = useState(null)
    const [shareVisible, setShareVisible] = useState(false);
    const [relatedData, setRelatedData] = useState([]);
    const [selectedVideoId, setSelectedVideoId] = useState(null)


    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    const subscribe = subscribeList?.results?.some((data) => data.channel.id === videoData?.author?.id);
    const filterData = relatedData?.filter((data) => data.id === selectedVideoId) || [];


    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                if (videoRef.current) {
                    setCurrentTime(videoRef.current.currentTime);
                    setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isPlaying]);

    const handleReplay = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10);
            setCurrentTime(videoRef.current.currentTime);
            setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
        }
    };

    const handleForward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.min(videoRef.current.duration, videoRef.current.currentTime + 10);
            setCurrentTime(videoRef.current.currentTime);
            setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
        }
    };

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch(error => {
                    console.error("Error attempting to play video:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // api calls
    const handleClickSub = () => {
        if (subscribe) {
            deleteSubscribe();
        } else {
            handleSubscribe();
        }
    }

    const handleSubscribe = async () => {
        setIsSubscribe(!isSubscribe);
        try {
            const rest = await dispatch(createSubscription({ channel_id: videoData?.author.id, user, notifications: "all" }))
            if (rest.error) {
                toast.error("Subscription failed")
            }
            else {
                dispatch(getUserSubscription(videoData?.author?.id))
                dispatch(getAllUserSubscription())
            }
        } catch (error) {
            console.log(error, "Subscription failed");

        }
    }

    const deleteSubscribe = () => {
        const filterData = subscribeList?.results?.filter((data) => data.channel.id === videoData?.author?.id);
        const chennel_id = filterData[0]?.id;
        setIsSubscribe(!isSubscribe);
        dispatch(DeleteSubscription(chennel_id)).then((result) => {
            if (result.error) {
                console.log(result, "Subscription failed");
            } else {
                dispatch(getUserSubscription(videoData?.author?.id))
                dispatch(getAllUserSubscription())
            }
        });
    }

    const handleSeek = (e) => {
        if (videoRef.current) {
            const seekTime = (e.nativeEvent.offsetX / e.target.clientWidth) * videoRef.current.duration;
            videoRef.current.currentTime = seekTime;
            setCurrentTime(seekTime);
            setProgress((seekTime / videoRef.current.duration) * 100);
        }
    };

    const handleNavigate = (id) => {
        if (id === user) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${id}`)
        }
    }

    const fetchData = useCallback(async () => {
        await dispatch(fetchVideoDetails(id));
        await dispatch(getAllUserSubscription())
        setRelatedData(videoData?.related_videos)
    }, [dispatch, id])



    const handleSomeAction = useCallback(async () => {
        if (videoData?.author?.id) {
            await dispatch(getUserSubscription(videoData?.author?.id));
        }
    }, [videoData?.author?.id, dispatch]);


    // video like and dislike function
    const handleLike = async (like) => {
        const payload = {
            user: userDetails.id,
            video: id,
            is_liking: like
        }

        dispatch(likeVideo(payload)).then((result) => {
            if (result.error) {
                console.log(result.error, "error")
            } else {
                console.log("like success")
            }
        }
        )
    }

    const handleComment = () => {
        setShowComment(!showComment);
        setShowCommentInput(false);
    }

    const hanldeAddComment = () => {
        setShowCommentInput(!showCommentInput);
    }

    //commment section

    const handleGetComment = useCallback(async () => {
        await dispatch(getComments(id))
    }, [dispatch, id])

    const handleChangeComment = async (e) => {
        setComment(e.target.value)
    }

    const handleSubmitComment = (isEdit, commentID) => {
        if (isEdit) {
            handleEditComment(commentID)
        } else {
            handleCreateComment()
        }
    }

    const handleCreateComment = async () => {
        const data = {
            video: id,
            content: comment

        }
        if (comment !== '') {
            try {
                const res = await dispatch(createComment(data));
                if (res.payload) {
                    setComment("")
                    handleGetComment()
                } else {
                    console.log('error')
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleEditComment = async (commentID) => {
        const data = {
            content: comment
        }
        if (comment !== '') {
            // try {
            dispatch(updateComments({ commentID, data })).then((res) => {
                if (res.payload) {
                    setComment("")
                    handleGetComment()
                    setEditComment(false)
                } else {
                    console.log('error')
                }
            })

            // } catch (error) {
            //     console.log(error)
            // }
        }
    }

    //API CALLS FOR DELETING COMMENTS
    const handleDeleteComment = async (commentId) => {
        try {
            const response = await dispatch(deleteComment(commentId));
            if (response.type === "deleteComment/fulfilled") {
                setVideoComments((prev) => prev.filter((comment) => comment.id !== commentId))

                setEditComment(false)
            }
        } catch (error) {
            console.error('Failed to delete comment:', error);
            // Handle error (e.g., show a notification
        }
    }

    // replay section

    const getCommentReplies = async (id) => {
        await dispatch(getReplies(id))
    }
    const handleSubmitReply = (event) => {
        if (editReply) {
            handleUpdateReply(event, replyId)
        } else {
            handleCreateReply(event)
        }
    }


    // create reply
    const handleCreateReply = async (event) => {
        event.preventDefault();
        const data = {
            comment: commentId,
            content: reply
        }
        try {
            await dispatch(createCommentReply(data)).then((res) => {
                if (res.error) {
                    console.log('error')
                } else {
                    getCommentReplies(commentId)
                    setReplyId(null);
                    setCommentId(null)
                    setReply('');
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleUpdateReply = async (event, replyId) => {
        event.preventDefault();
        const data = {
            content: reply,
            comment: commentId
        }
        try {
            await dispatch(updateCommentReply({ replyId, data })).then((res) => {
                if (res.error) {
                    console.log('error')
                } else {
                    getCommentReplies(commentId)
                    setReplyId(null);
                    setCommentId(null)
                    setReply('');
                }
            })
        } catch (error) {
            console.log(error)
        }

    }

    const handleDeleteCommentReply = async (replyId, CommentId) => {
        try {
            const response = await dispatch(deleteReplies(replyId))
            if (response.type === "deleteReplies/fulfilled") {
                setCommentReplies((prev) => prev.filter((reply) => reply.id !== replyId))
            }
        } catch (error) {
            console.error('Failed to delete comment:', error);
            // Handle error (e.g., show a notification

        }

    }

    // like dislike comment
    const handleLikeComment = async (id, like) => {
        const data = {
            user: userDetails?.id,
            comment: id,
            is_liking: like === "Like" ? true : false,
        }

        await dispatch(likeComment(data)).then((result) => {
            if (result.error) {
                console.log(result.error, "error")
            } else {
                if (like === "Like") {

                    setVideoComments((prev) => prev.map((comment) => {
                        if (comment.id === id) {
                            return {
                                ...comment,
                                is_user_liking: true,
                                is_user_disliking: false
                            }
                        }
                        return comment
                    }))
                } else if (like === "Dislike") {
                    setVideoComments((prev) => prev.map((comment) => {
                        if (comment.id === id) {
                            return {
                                ...comment,
                                is_user_liking: false,
                                is_user_disliking: true
                            }
                        }
                        return comment
                    }))
                }
            }
        })
    }

    const handleDislikeComment = async (id) => {
        await dispatch(removeLikeDislikeComment(id)).then((result) => {
            if (result.error) {
                console.log(result.error, "error")
            } else {
                setVideoComments((prev) => prev.map((comment) => {
                    if (comment.id === id) {
                        return {
                            ...comment,
                            is_user_liking: false,
                            is_user_disliking: false
                        }
                    }
                    return comment
                }))
            }
        })
    }



    // download functionality
    // const downloadVideo = async () => {
    //     const postId = id;
    //     const author = videoData?.author?.full_name;
    //     const videoUrl = videoData?.video;

    //     if (!videoUrl) {
    //         alert("Video URL not available.");
    //         return;
    //     }

    //     try {
    //         const res = await dispatch(getDownload(postId))
    //         if (res.error) {
    //             alert("Download Video failed ");
    //             return
    //         } else {
    //             setMenu(false)
    //             const response = await fetch(videoUrl);
    //             const blob = await response.blob();
    //             const url = window.URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.download = `kindviewer_${author || 'kindviewer_video'}.mp4`; // Custom file name
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             window.URL.revokeObjectURL(url);
    //         }
    //     } catch (error) {
    //         console.error("Error downloading the video:", error);
    //         alert("Failed to download the video.");
    //     }
    // };


    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert
        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds
        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };
        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }
        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleShare = () => {
        setShareVisible(!shareVisible);
        if (!shareVisible) {
            setOpenVideoInfo(null)
        }
    }

    const handleBlockChannel = async (channelId) => {
        if (channelId === user) {
            return toast('You cannot block yourself',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        } else {
            await dispatch(blockChannel(channelId)).then((res) => {
                toast('Channel Blocked Successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setTimeout(setOpenVideoInfo(null), 500);
                setRelatedData(relatedData.filter((data) => data.author.id !== channelId));
            })
        }
    }

    const handleAddWatchLater = async (videoId) => {
        try {
            await dispatch(addWatchList(videoId)).then((result) => {
                if (result.error) {
                    toast('Already Added to Watch Later',
                        {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                    setTimeout(setOpenVideoInfo(null), 500);

                }

                else {
                    setTimeout(setOpenVideoInfo(null), 500);

                    toast('saved to watch later',
                        {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                }
            })

        } catch (error) {
            toast.error("Video already in Watch Later list",)
            console.log(error?.data?.message, "error");
        }
    }


    const handleDownload = async (videoId) => {
        await dispatch(getDownload(videoId)).then((res) => {
            if (res?.error) {
                toast('Already downloaded',
                    {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setTimeout(setOpenVideoInfo(null), 500);
            } else {
                toast('downloaded successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setTimeout(setOpenVideoInfo(null), 500);
            }
        })
    }
    const handleNotIntrested = async (videoId) => {
        await dispatch(blockVideo(videoId)).then((res) => {
            toast('Removed from feed',
                {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
            setTimeout(setOpenVideoInfo(null), 500);
            setRelatedData(relatedData.filter((data) => data.id !== videoId));
        })

    }




    useEffect(() => {
        handleSomeAction();
    }, [handleSomeAction]);

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        setVideoComments(comments)
    }, [comments])

    useEffect(() => {
        setCommentReplies(replies)
    }, [replies])

    useEffect(() => {
        handleGetComment()
    }, [handleGetComment])

    // if (!videoData) {
    //     return <div>Video not found</div>;
    // }

    console.log(isLoading, "isLoading")
    return (
        <Fragment>
            {!isLoading && <div className="bg-black h-full relative">
                <Toaster position="top-right" />
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-600 text-[9px] uppercase font-bold">
                        KV / FE / Video Details / V3 / 01 Oct, 2024
                    </p>
                    <p className="text-black text-[9px] mt-[-2px]">
                        {copyright}
                    </p>
                </div>

                <div className="sticky top-0 z-50">
                    <VideoHeader
                        title={videoData?.title}
                        author={videoData?.author?.full_name}
                        showTitle={showTitle}
                        setShowTitle={setShowTitle}
                        menu={menu}
                        setMenu={setMenu}
                        navigate={navigate}
                        video_id={id}
                        downloadVideo={handleDownload}
                    />

                    <div className="p-2 relative group bg-black">
                        {!isLoading && (
                            <video
                                loop
                                autoPlay
                                // muted={mute}
                                muted={true}
                                ref={videoRef}
                                disablePictureInPicture
                                src={videoData?.video}
                                poster={videoData?.thumbnail}
                                onLoadedMetadata={() => setDuration(videoRef.current.duration)}
                                className="w-full h-[9.5rem] min-h-[10rem] object-cover rounded-sm"
                            />
                        )}
                        <MainVideoControls
                            handleReplay={handleReplay}
                            handlePlayPause={handlePlayPause}
                            isPlaying={isPlaying}
                            handleForward={handleForward}
                            handleSeek={handleSeek}
                            progress={progress}
                            currentTime={currentTime}
                            duration={duration}
                            formatTime={formatTime}
                            mute={mute}
                            setMute={setMute}
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            handleToggle={setShareVisible}
                        />
                    </div>
                </div>

                {showComment && (
                    <VideoControls
                        // videoData={videoData}
                        title={videoData?.title}
                        author={videoData?.author?.handle}
                        authorId={videoData?.author?.id}
                        authorProfile={videoData?.author?.profile_picture}
                        comments={videoComments}
                        viewCount={videoData?.views_count}
                        likeCount={videoData?.likes_count}
                        description={videoData?.description}
                        handleNavigate={handleNavigate}
                        handleClickSub={handleClickSub}
                        subscribe={subscribe}
                        userSubscribeList={userSubscribeList}
                        showDescription={showDescription}
                        setShowDescription={setShowDescription}
                        showComment={showComment}
                        setShowComment={setShowComment}
                        handleLike={handleLike}
                        userLiked={videoData?.is_user_liking}
                        downloadVideo={handleDownload}
                        video_id={id}
                        handleToggle={setShareVisible}
                    />
                )}

                {showComment && (
                    <RelatedVideos
                        id={id}
                        relatedVideoData={videoData?.related_videos}
                        navigate={navigate}
                        openVideoInfo={openVideoInfo}
                        handleToggle={handleToggle}
                        setSelectedVideoId={setSelectedVideoId}
                        formatTime={timeAgo}
                        shareVisible={shareVisible}
                    />
                )}

                {!showComment && (
                    <CommentView
                        closeComment={handleComment}
                        showInput={() => hanldeAddComment()}
                        showCommentInput={showCommentInput}
                        showComment={showComment}
                        postId={id}
                        getVideoComments={handleGetComment}
                        commentText={comment}
                        setCommentText={setComment}
                        handleChangeComment={handleChangeComment}
                        handleSubmitComment={handleSubmitComment}
                        DeleteComment={handleDeleteComment}
                        likeComment={handleLikeComment}
                        dislikeComment={handleDislikeComment}
                        comments={videoComments}
                        replies={commentReplies}
                        loading={commentLoading}
                        repliesLoading={repliesLoading}
                        getCommentReplies={getCommentReplies}
                        setEditComment={setEditComment}
                        editComment={editComment}
                        replyId={replyId}
                        setReplyId={setReplyId}
                        reply={reply}
                        setReply={setReply}
                        setCommentId={setCommentId}
                        commentId={commentId}
                        handleCreateReply={handleSubmitReply}
                        handleDeleteReply={handleDeleteCommentReply}
                        setEditReply={setEditReply}
                        editReply={editReply}
                    />
                )}
            </div>}
            {isLoading && <LoadingSkeletonVideoDetails label={"KV / FE / Video Details / V3 / 01 Oct, 2024"} />}

            {openVideoInfo && (
                <FourDotDrawer
                    title={filterData[0]?.title}
                    videoId={filterData[0]?.id}
                    // savePlaylist={() => ""}
                    onClose={() => handleToggle(openVideoInfo)}
                    handleShare={handleShare}
                    handleBlockChannel={handleBlockChannel}
                    channelId={filterData[0]?.author.id}
                    handleDownload={handleDownload}
                    handleNotIntrested={handleNotIntrested}
                    handleAddWatchLater={handleAddWatchLater}
                />
            )}
            {shareVisible &&
                <div className="absolute top-10 z-50  h-full w-full">
                    <Share
                        mainurl={"https://kindviewer.com/video/"}
                        handleShare={handleShare}
                        id={selectedVideoId}
                    />
                </div>
            }
        </Fragment>
    );
};

export default VideoDetails;