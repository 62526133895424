import React, { useEffect, useState } from 'react'

export default function ClipsEditModal({ handleEditClip, clipsDetails, loading, handleUpdate }) {
   const [description, setDescription] = useState("");
   const [video, setVideo] = useState(null);
   const [error, setError] = useState(null)


   const handleSubmitEditClip = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      if (description) {
         formData.append("description", description);
      }
      if (description) {
         handleUpdate(formData, clipsDetails?.id)
      } else {
         setError("Description is required")
      }
   }

   useEffect(() => {
      setDescription(clipsDetails?.description)
      setVideo(clipsDetails?.video)
   }, [clipsDetails])
   return (
      <div className='col-span-12 absolute h-full w-full z-40 top-0'>
         <div className='relative h-full w-full flex justify-start items-start'>
            <div className="absolute w-full h-full bg-black opacity-50 z-0"></div>
            <div className="px-4 z-50 w-full mt-24">
               <div className="bg-gray-900 grid grid-cols-12 w-full rounded-[4px] py-4">
                  <div className="col-span-12 w-full flex justify-between items-center px-2">
                     <h1 className="text-[16px] text-white font-semibold">Edit Clip</h1>
                  </div>
                  <div className="col-span-12 w-full p-2 relative">
                     <video
                        muted
                        loop
                        autoPlay
                        disablePictureInPicture
                        src={video}
                        controls={false}
                        className={`object-cover rounded-sm h-[14rem] md:h-[10rem] w-full bg-black`}
                     />

                  </div>
                  <form onSubmit={(e) => handleSubmitEditClip(e)} className="col-span-12 mt-4 px-2 w-full">


                     <div className="mb-1">
                        <textarea
                           rows={3}
                           value={description}
                           placeholder="Add a description..."
                           onChange={(e) => setDescription(e.target.value)}
                           className="w-full p-2  border outline-none resize-none rounded-md placeholder:text-[14px]"
                        />
                        <p className="text-red-500 text-[12px]">{error}</p>

                     </div>
                     {!loading && <div className="flex justify-between gap-6">
                        <button
                           onClick={() => handleEditClip()}
                           className="bg-gray-800 text-white w-full px-4 py-1 text-[14px] rounded-md hover:bg-gray-900 transition-all duration-300"
                        >
                           Cancel
                        </button>
                        <button
                           type="submit"
                           className="bg-gray-800 text-white w-full px-4 py-1 text-[14px] rounded-md hover:bg-gray-900 transition-all duration-300"
                        >
                           Update
                        </button>
                     </div>}
                  </form>
               </div>
            </div>
         </div>
      </div>

   )
}





