import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { copyright } from '../../utils/copyright'

export default function LoadingSkeletonVideoDetails({label}) {
    return (
        <div className="bg-black h-full relative mb-24">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                  
                    {label}
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>
            <div className='grid grid-cols-12 px-2'>
                <div className='col-span-12 flex justify-between items-center'>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "1rem" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.5rem", width: "7rem", borderRadius: "10px" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "1rem" }} />
                </div>
                <div className='col-span-12 py-1'>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "9.5rem", width: "100%" }} />
                </div>
                <div className='col-span-12 flex justify-between items-center'>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.2rem", width: "14rem" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "2rem" }} />
                </div>
                <div className='col-span-12 flex justify-between items-center'>
                    <div className='flex gap-2 items-center'>
                        <Skeleton circle baseColor="#202020" highlightColor="#444" style={{ height: "1.5", width: "1.5" }} />
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.5", width: "5rem", borderRadius: "20px" }} />
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "4rem" }} />
                    </div>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "1rem", borderRadius: "20px" }} />

                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "1rem", borderRadius: "20px" }} />

                </div>
                <div className='col-span-12 flex justify-between items-center py-1'>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "3rem", borderRadius: "10px" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "3rem", borderRadius: "10px" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "3rem", borderRadius: "10px" }} />
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1rem", width: "3rem", borderRadius: "10px" }} />
                </div>
                <div className='col-span-12'>
                    <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "2rem", width: "100%", borderRadius: "10px" }} />
                </div>
            </div>
            {
                [1, 2, 3].map((_, index) => (<div key={index} className="grid grid-cols-12 px-2 py-1">
                    <div className="col-span-12">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                    </div>
                    <div className="col-span-2">
                        <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "2rem", width: "2rem" }} />
                    </div>
                    <div className="col-span-9 flex flex-col justify-center -ml-2">
                        <div>
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "60%" }} />
                        </div>
                        <div className="-mt-10">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "100%" }} />
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-end">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "12px", width: "12px", borderRadius: "1px", }} />
                    </div>
                </div>
                ))}
        </div>
    )
}
