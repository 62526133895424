import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";

const mainUrl = REACT_APP_MAIN_URL;

export const getAllSubscribed = createAsyncThunk(
    "getAllSubscribed",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/subscriptions/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getSubscribedVideos = createAsyncThunk(
    "getSubscribedVideos",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/subscribed_videos/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const hideChannel = createAsyncThunk(
    "hideChannel",
    async (channel_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/block-subscribed_channel/${channel_id}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const unHideChannel = createAsyncThunk(
    "unHideChannel",
    async (channel_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}/api/unblock-subscribed_channel/${channel_id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const SubscribedSlice = createSlice({
    name: "subscribed",
    initialState: {
        subLoading: false,
        data: {},
        subVideos: [],
        error: null,
    },
    extraReducers: (builder) => {
        // getAllSubscribed
        builder.addCase(getAllSubscribed.pending, (state) => {
            state.subLoading = true;
        });
        builder.addCase(getAllSubscribed.fulfilled, (state, action) => {
            state.subLoading = false;
            state.data = (action.payload);
        });
        builder.addCase(getAllSubscribed.rejected, (state, action) => {
            state.subLoading = false;
            state.error = action.payload;
        });

        // getSubscribedVideos
        builder.addCase(getSubscribedVideos.pending, (state) => {
            state.subLoading = true;
        });
        builder.addCase(getSubscribedVideos.fulfilled, (state, action) => {
            state.subLoading = false;
            state.subVideos = action.payload;
        });
        builder.addCase(getSubscribedVideos.rejected, (state, action) => {
            state.subLoading = false;
            state.error = action.payload;
        });
        // getSubscribedVideos
        builder.addCase(unHideChannel.pending, (state) => {
            state.subLoading = true;
        });
        builder.addCase(unHideChannel.fulfilled, (state, action) => {
            state.subLoading = false;
        });
        builder.addCase(unHideChannel.rejected, (state, action) => {
            state.subLoading = false;
            state.error = action.payload;
        });
    }
});

export default SubscribedSlice.reducer;
