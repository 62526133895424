import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL;

export const fetchVideoDetails = createAsyncThunk(
    "fetchVideoDetails",
    async (video_Id) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/videos/${video_Id}/`, config);
        const response = await request.data;
        return response;
    }
);

export const likeVideo = createAsyncThunk(
    "likeVideo",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/video-ratings/`, data, config);
        const response = await request.data;
        return response;
    }
);

const videoDetailsSlice = createSlice({
    name: "videoDetailsSlice",
    initialState: {
        isLoading: false,
        videoData: null,
        error: null,
    },
    extraReducers: (builder) => {
        //videoListSlice
        builder.addCase(fetchVideoDetails.pending, (state) => {
            state.isLoading = true
            state.videoData = null;
            state.error = null;
        });
        builder.addCase(fetchVideoDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.videoData = action.payload;
            state.error = null;
        });
        builder.addCase(fetchVideoDetails.rejected, (state) => {
            state.isLoading = false;
            state.videoData = null;
            state.error = true;
        });
    }
});

export default videoDetailsSlice.reducer;
