import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const uploadMusic = createAsyncThunk( 
    "uploadMusic",
    async ({ musicData, progressCallback, cancelToken }, { rejectWithValue }) => {
        try {
            const config = {
                ...getAccessConfig(),
                cancelToken,  // Add the cancel token
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (progressCallback) progressCallback(progress);
                },
            };
            const request = await axios.post(`${mainUrl}api/musickv/audios/upload/`, musicData, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllMusic = createAsyncThunk(
    "getAllMusic",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/get_all_audios/`, config);
            const response = await request.data;
            return Array.isArray(response) ? response : [];
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const mostPlayedMusic = createAsyncThunk(
    "mostPlayedMusic",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/most_played/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const latestMusic = createAsyncThunk(
    "latestMusic",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/latest/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const playIncreaseById = createAsyncThunk(
    "playIncreaseById",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/musickv/audios/${id}/play/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const saveUnsaveMusicById = createAsyncThunk(
    "saveUnsaveMusicById",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/musickv/audios/${id}/toggle_bookmark/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const savedMusicList = createAsyncThunk(
    "savedMusicList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/saved_audios/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// to show the bookmarked music icon list for user
export const savedMusicListForUser = createAsyncThunk(
    "savedMusicListForUser",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/saved_audios/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userUploadMusic = createAsyncThunk(
    "userUploadMusic",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/user-audios/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const musicByCategory = createAsyncThunk(
    "musicByCategory",
    async (category, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/category/${category}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteMusicById = createAsyncThunk(
    "deleteMusicById",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/musickv/audios/${id}/delete_audio/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Search music ❌ Not used in the MusicPage component
export const searchMusic = createAsyncThunk(
    "searchMusic",
    async (searchQuery, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/musickv/audios/music/${searchQuery}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const musicSlice = createSlice({
    name: "music",
    initialState: {
        loading: false,
        data: [],
        savedMusicList: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Upload music
        builder.addCase(uploadMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(uploadMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(uploadMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Get all music
        builder.addCase(getAllMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = Array.isArray(action.payload) ? action.payload : [];
        });
        builder.addCase(getAllMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Most played music
        builder.addCase(mostPlayedMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(mostPlayedMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = Array.isArray(action.payload) ? action.payload : [];
        });
        builder.addCase(mostPlayedMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Latest music
        builder.addCase(latestMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(latestMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = Array.isArray(action.payload) ? action.payload : [];
        });
        builder.addCase(latestMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Play increase by id
        builder.addCase(playIncreaseById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(playIncreaseById.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            // Ensure state.data remains an array
            if (Array.isArray(state.data)) {
                const updatedData = state.data.map((music) =>
                    music.id === action.payload.id ? { ...music, plays: action.payload.plays } : music
                );
                state.data = updatedData;
            }
        });
        builder.addCase(playIncreaseById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Save music by id
        builder.addCase(saveUnsaveMusicById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(saveUnsaveMusicById.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            // Ensure state.data remains an array
            if (Array.isArray(state.data)) {
                const updatedData = state.data.map((music) =>
                    music.id === action.payload.id ? { ...music, saved: action.payload.saved } : music
                );
                state.data = updatedData;
            }
        });
        builder.addCase(saveUnsaveMusicById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Saved music list
        builder.addCase(savedMusicList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(savedMusicList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = Array.isArray(action.payload) ? action.payload : [];
        });
        builder.addCase(savedMusicList.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Saved music list for user
        builder.addCase(savedMusicListForUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(savedMusicListForUser.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.savedMusicList = action.payload;
        });
        builder.addCase(savedMusicListForUser.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // User upload music list
        builder.addCase(userUploadMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(userUploadMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(userUploadMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Music by category
        builder.addCase(musicByCategory.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(musicByCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = Array.isArray(action.payload) ? action.payload : [];
        });
        builder.addCase(musicByCategory.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Delete music by id
        builder.addCase(deleteMusicById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteMusicById.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = state.data.filter((music) => music.id !== action.payload.id);
        });
        builder.addCase(deleteMusicById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Search music ❌ Not used in the MusicPage component
        builder.addCase(searchMusic.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(searchMusic.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(searchMusic.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default musicSlice.reducer;