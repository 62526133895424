import React, { useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { social_logo } from "../data";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../redux/apislice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import PulseLoader from "react-spinners/PulseLoader";
import { BiHide, BiShow } from "react-icons/bi";
import { jwtDecode } from "jwt-decode";
import { profileDetails } from "../redux/apislice/profileSlice";

const LoginPage = () => {
   const lable = "KV / FE / LOGIN / V1 / 27 July, 2024";

   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.auth);

   const [email, setEmail] = useState("abinashk10006@tutanota.com");
   const [password, setPassword] = useState("Abinash@1234");
   const [rememberMe, setRememberMe] = useState(false);
   const [showPassword, setShowPassword] = useState(false);


   // Load remember me state and decrypt email/password from cookies on component mount
   useEffect(() => {
      const rememberMeState = Cookies.get("rememberMe");
      if (rememberMeState) {
         setRememberMe(JSON.parse(rememberMeState));
         const storedEmail = Cookies.get("email");
         const storedPassword = Cookies.get("password");
         if (storedEmail && storedPassword) {
            const decryptedEmail = decryptData(storedEmail);
            const decryptedPassword = decryptData(storedPassword);
            setEmail(decryptedEmail);
            setPassword(decryptedPassword);
         }
      }
   }, []);

   const togglePasswordVisibility = () => setShowPassword(!showPassword);

   const handleSubmit = (e) => {
      e.preventDefault();
      const loginDetails = { email, password };

      dispatch(userLogin(loginDetails)).then((result) => {
         if (result.error) {
            toast.error("Login failed ! Please check your credentials");
         } else {
            toast.success("Login Successful");
            const token = result.payload.access;
            const decoded = jwtDecode(token);
            Cookies.set("accessToken", result.payload.access);
            Cookies.set("refreshToken", result.payload.refresh);
            Cookies.set("userId", decoded.user_id);
            dispatch(profileDetails(decoded.user_id));
            if (!loading) {
               setTimeout(() => {
                  navigate("/home");
                  toast.dismiss();
               }, 2000);
            }

            // Store email and password in cookies if remember me is checked
            if (rememberMe) {
               const encryptedEmail = encryptData(email);
               const encryptedPassword = encryptData(password);
               Cookies.set("email", encryptedEmail);
               Cookies.set("password", encryptedPassword);
               Cookies.set("rememberMe", JSON.stringify(rememberMe));
            } else {
               // If remember me is unchecked, remove email and password from cookies
               Cookies.remove("email");
               Cookies.remove("password");
               Cookies.remove("rememberMe");
            }
         }
      });
   }

   // Encrypt data using AES encryption
   const encryptData = (data) => {
      return CryptoJS.AES.encrypt(data, "secret_key").toString();
   }

   // Decrypt data using AES decryption
   const decryptData = (encryptedData) => {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
      return bytes.toString(CryptoJS.enc.Utf8);
   }


   return (
      <div className="h-full bg-[#2C672E] relative">
         <Toaster position="top-right" />
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
         </div>

         <h1 className="text-[3rem] px-3 font-semibold uppercase text-white">
            kv
         </h1>
         <form className="px-6 mt-6" onSubmit={handleSubmit}>
            <p className="text-white font-semibold text-[24px] md:text-[24px] text-center">
               Welcome Back !
            </p>
            <div className="mt-4">
               <label className="block text-[#D9D9D9] font-normal text-[20px] md:text-[14px]" htmlFor="email">
                  Email
               </label>
               <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  className="w-full px-3 py-3 md:py-2 mt-1 text-[18px] md:text-[14px] text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
               />
            </div>
            <div className="mt-2 ">
               <label className="block text-[#D9D9D9] font-normal mb-1 text-[20px] md:text-[14px]" htmlFor="email">
                  Password
               </label>
               <div className="relative">
                  <input
                     type={showPassword ? "text" : "password"}
                     required
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     placeholder="Enter your password"
                     className="w-full px-3 py-3 md:py-2 text-[18px] md:text-[14px] text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                  />
                  <button
                     type="button"
                     onClick={togglePasswordVisibility}
                     className="absolute inset-y-0 right-3 flex items-center outline-none text-[#D9D9D9] cursor-pointer"
                  >
                     {showPassword ? <BiHide className="text-[24px] md:text-[20px]" /> : <BiShow className="text-[24px] md:text-[20px]" />}
                  </button>
               </div>
            </div>
            <div className="mt-2 flex items-center justify-between">
               <div className="flex items-center">
                  <input
                     type="checkbox"
                     onChange={(e) => setRememberMe(e.target.checked)}
                     checked={rememberMe}
                     className="mr-1 border-none focus:outline-none cursor-pointer"
                  />
                  <p className="text-white text-[16px] md:text-[12px]">
                     Remember me
                  </p>
               </div>
               <p className="cursor-pointer text-[16px] md:text-[12px] text-[#00FF0A] font-semibold" onClick={() => navigate("/recoverpass")}>
                  Recover password
               </p>
            </div>
            <button
               disabled={loading}
               type="submit"
               className="w-full py-2 mt-4 text-[18px] md:text-[14px] text-center font-bold text-white bg-[#00FF0A] rounded-md focus:outline-none"
            >
               {loading ?
                  <PulseLoader
                     color={"#fff"}
                     loading={loading}
                     size={10}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  : "Login"}
            </button>
            <div className="flex items-center justify-between gap-4 mt-2">
               <div className="h-[0.4px] bg-white rounded-full w-full" />
               <p className="text-[12px] md:text-[10px] text-white whitespace-nowrap">or log in with</p>
               <div className="h-[0.4px] bg-white rounded-full w-full" />
            </div>

            <div className="flex items-center justify-between mt-2">
               {social_logo.map((data) => (
                  <div
                     key={data.id}
                     onClick={() => navigate("/soon")}
                     className="bg-white p-2 rounded cursor-pointer"
                  >
                     {data.src ? (
                        <img
                           alt={data.name}
                           src={data.src}
                           className="h-6 w-6 md:h-5 md:w-5"
                        />
                     ) : (
                        <div className="h-6 w-6 md:h-5 md:w-5">
                           <p className="text-[18px] md:text-[14px] font-extrabold  text-center">
                              {data.name}
                           </p>
                        </div>
                     )}
                  </div>
               ))}
            </div>
         </form>

         <div className="absolute flex items-center justify-center gap-1 bottom-5 w-full">
            <p className="text-[14px] text-white">Don’t have an account?</p>
            <p className="font-semibold text-[#00FF0A] text-[14px] cursor-pointer" onClick={() => navigate("/signup")}>Sign Up</p>
         </div>
      </div>
   )
}

export default LoginPage