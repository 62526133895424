import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Import your reducers
import securityReducer from "./apislice/securitySlice";
import authReducer from "./apislice/authSlice";
import profileReducer from "./apislice/profileSlice";
import createVideoReducer from "./apislice/createSlice";
import videoListReducer from "./apislice/videoListSlice";
import videoDetailsReducer from "./apislice/videoDetailsSlice";
import uploadVideoReducer from "./apislice/uploadVideoSlice";
import subscriptionReducer from "./apislice/subscriptionSlice";
import playlistReducer from "./apislice/playListSlice";
import editProfileReducer from "./apislice/editProfileSlice";
import recoveryPassReducer from "./apislice/recoveryPasswordSlice";
import clipsReducer from "./apislice/clips/clipsSlice";
import clipsCommentReducer from "./apislice/clips/clipsCommentSlice";
import clipsLikeReducer from "./apislice/clips/clipsLikeSlice";
import clipsShareReducer from "./apislice/clips/clipsShareSlice";
import clipsCommentLikeReducer from "./apislice/clips/clipsCommentLikeSlice";
import watchLaterListReducer from "./apislice/watchListSlice";
import watchHistoryListReducer from "./apislice/watchHistorySlice";
import downloadListReducer from "./apislice/downloadSlice";
import commentReducer from "./apislice/commentSlice";
import musicReducer from "./apislice/music/musicSlice";
import clipsSaveReducer from "./apislice/clips/clipsSaveSlice";
import clipsTagReducer from "./apislice/clips/clipsTagSlice";
import clipsReportReducer from "./apislice/clips/clipsReport";
import createPostReducer from "./apislice/createPost/createPostSlice";
import createPostDraftReducer from "./apislice/createPost/createPostDraftSlice";
import podcastReducer from "./apislice/podcast/podcastSlice";
import contactReducer from "./apislice/contactSlice";
import notificationReducer from "./apislice/notification/notificationSlice";
import trendingVideoListReducer from "./apislice/trendingVideosSlice";
import podcastCommentReducer from "./apislice/podcast/commentsSlice";
import podcastReplyReducer from "./apislice/podcast/podcastReplies";
import searchReducer from "./apislice/search/searchSlice";
import helpReducer from "./apislice/help/helpSlice";
import blogReducer from "./apislice/blogSlice";
import calendarReducer from "./apislice/CalendarSlice";
import postedClipsReducer from "./apislice/clips/postedClipsSlice";
import otherUserReducer from "./apislice/otherUserAccount/otherUserAccount";
import subscribedReducer from "./apislice/subscribed/subscribedSlice";
import blockReaducer from "./apislice/blockSlice";
import userPodcastReducer from "./apislice/podcast/userPodcastSlice";

// Combine all the reducers into a single root reducer
const rootReducer = combineReducers({
    security: securityReducer,
    auth: authReducer,
    profile: profileReducer,
    otherUserProfile: otherUserReducer,
    uploadVideo: uploadVideoReducer,
    createVideo: createVideoReducer,
    videoList: videoListReducer,
    videoDetails: videoDetailsReducer,
    comment: commentReducer,
    subscription: subscriptionReducer,
    playlist: playlistReducer,
    editProfile: editProfileReducer,
    recoveryPass: recoveryPassReducer,
    clips: clipsReducer,
    clipsComment: clipsCommentReducer,
    clipsLike: clipsLikeReducer,
    clipsShare: clipsShareReducer,
    clipsSaved: clipsSaveReducer,
    clipsTag: clipsTagReducer,
    clipsCommentLike: clipsCommentLikeReducer,
    clipsReport: clipsReportReducer,
    postedClips: postedClipsReducer,
    watchLaterList: watchLaterListReducer,
    watchHistoryList: watchHistoryListReducer,
    downloadList: downloadListReducer,
    createPost: createPostReducer,
    draft: createPostDraftReducer,
    podcast: podcastReducer,
    podcastComment: podcastCommentReducer,
    podcastReplies: podcastReplyReducer,
    userPostedPodcast : userPodcastReducer,
    musicList: musicReducer,
    trendingVideos: trendingVideoListReducer,
    contact: contactReducer,
    notification: notificationReducer,
    search: searchReducer,
    help: helpReducer,
    blog: blogReducer,
    calendar: calendarReducer,
    subscribed: subscribedReducer,
    blocked: blockReaducer,
});

// Configuration for redux-persist
const persistConfig = {
    key: "root",
    version: 1,
    storage, // defaults to localStorage
    whitelist: ["profile"], // specify reducers to persist
};

// Create a persisted reducer using the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store configuration with middleware for redux-persist
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

// Export the persisted store
export const persistor = persistStore(store);
