import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const blockChannel = createAsyncThunk(
    "blockChannel",
    async (channelId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/block_channel/${channelId}/`, {}, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const unBlockChannel = createAsyncThunk(
    "unBlockChannel",
    async (channelId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/unblock_channel/${channelId}/`, {}, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getBlockedChannels = createAsyncThunk(
    "getBlockedChannels",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/blocked-channel_list/`, config);
            console.log(request, "request");
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const blockVideo = createAsyncThunk("blockVideo", async (videoId, { rejectWithValue }) => {
    try {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/block_video/${videoId}/`, {}, config);

        const response = await request.data;
        return response.results;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);



const blockSlice = createSlice({
    name: "Block",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Blog List
        builder.addCase(blockChannel.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(blockChannel.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(blockChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // Blog List
        builder.addCase(blockVideo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(blockVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(blockVideo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        // GET BLOCKED CHANNEL LIST
        builder.addCase(getBlockedChannels.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBlockedChannels.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getBlockedChannels.rejected, (state) => {
            state.loading = false;
            state.error = null;
        });
    },
});

export default blockSlice.reducer;